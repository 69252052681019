import { request } from '@/utils/request';

const apiBase = '/project-api';

class ValidationsService {
  loadProfiles = () => request('/profiles/active', false, { apiBase });

  loadValidations = (entityType) => request(`/validations/${entityType}`, false, { apiBase });

  createValidation = ({ entityType, type, context }) => request(`/validations/${entityType.toLowerCase()}/${type}/${context}`, false,
    {
      method: 'post',
      apiBase,
    });

  deleteValidation = ({ entityType, resultId }) => request(
    `/validation/${entityType.toLowerCase()}/${resultId}`,
    false,
    {
      method: 'delete',
      apiBase,
    },
  );

  readQuestions = ({ questionTypeId }) => request(
    `/question/${questionTypeId}`,
    false,
    { apiBase },
  );

  updateQuestions = ({
    questionTypeId, questions,
  }) => {
    const resultList = questions.map((response) => {
      const { resultId, responseCodes } = response;
      return { resultId, responseCodes };
    });

    return request(
      `/question/${questionTypeId}`,
      false,
      {
        method: 'put',
        data: { resultList },
        apiBase,
      },
    );
  }

  readPersonalProfile = ({ personalId }) => request(
    `/personal-profile/${personalId}`,
    false,
    { apiBase },
  );

  updatePersonalProfile = ({
    personalId, profile,
  }) => request(
    `/personal-profile/${personalId}`,
    false,
    {
      method: 'put',
      data: { personalResults: profile.personalResults },
      apiBase,
    },
  );
}

export default new ValidationsService();
