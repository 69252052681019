import Vue from 'vue';
import ValidationsService from '@/services/validations.service';
import { toastSuccess, toastUnableToComplete } from '@/utils/toast';
import { isNotEmpty } from '@/utils/validators';

const initialState = {
  loading: false,
  error: false,
  subjectValidations: [],
  questions: { },
  personalProfiles: { },
  profiles: null,
};

const validations = {
  namespaced: true,
  state: initialState,
  getters: {
    isLoading: (state) => state.loading,
    hasValidations: (state) => isNotEmpty(state.subjectValidations),
    hasQuestionsResponse: (state) => (questionTypeId) => isNotEmpty(state.questions[questionTypeId]),
    hasPersonalProfile: (state) => (personalId) => isNotEmpty(state.personalProfiles[personalId]),
    hasProfiles: (state) => isNotEmpty(state.profiles),
    getValidationsList: (state) => (type) => state.subjectValidations.filter((item) => item.type === type),
    getValidationsListContext: (state) => ({ type, context }) => state.subjectValidations
      .filter((item) => item.type === type && item.context === context),
    getQuestionsResponse: (state) => (questionTypeId) => state.questions[questionTypeId] || [],
    getPersonalProfile: (state) => (personalId) => state.personalProfiles[personalId] || {},
    getProfiles: (state) => state.profiles || [],
  },
  actions: {
    readProfiles({ commit }) {
      commit('setLoading', true);
      commit('clearProfiles');
      return ValidationsService.loadProfiles().then(
        (response) => {
          commit('readProfilesSuccess', { data: response.data });
          commit('setLoading', false);
          return Promise.resolve(response);
        },
        (error) => {
          commit('clearProfiles');
          commit('setLoading', false);
          toastUnableToComplete();
          return Promise.reject(error);
        },
      );
    },
    readValidations({ commit }, entityType) {
      commit('setLoading', true);
      commit('clearValidations');
      return ValidationsService.loadValidations(entityType).then(
        (response) => {
          commit('loadValidationsSuccess', { data: response.data });
          commit('setLoading', false);
          return Promise.resolve(response.data);
        },
        (error) => {
          commit('clearValidations');
          commit('setLoading', false);
          toastUnableToComplete();
          return Promise.reject(error);
        },
      );
    },
    deleteValidation({ commit }, { entityType, resultId }) {
      commit('setLoading', true);
      return ValidationsService.deleteValidation({ entityType, resultId }).then(
        (response) => {
          commit('setLoading', false);
          return Promise.resolve(response);
        },
        (error) => {
          commit('setLoading', false);
          toastUnableToComplete();
          return Promise.reject(error);
        },
      );
    },
    addValidation({ commit }, { validation }) {
      commit('appendValidation', { validation });
    },
    createQuestons({ commit }, { entityType, context }) {
      commit('setLoading', true);
      return ValidationsService.createValidation({ entityType, context, type: 'QUESTION' }).then(
        (response) => {
          commit('setLoading', false);
          return Promise.resolve(response.data);
        },
        (error) => {
          commit('setLoading', false);
          toastUnableToComplete();
          return Promise.reject(error);
        },
      );
    },
    readQuestions({ commit }, { questionTypeId }) {
      commit('setLoading', true);
      commit('clearQuestions', { questionTypeId });
      return ValidationsService.readQuestions({ questionTypeId }).then(
        (response) => {
          commit('readQuestionsSuccess', { questionTypeId, data: response.data });
          commit('setLoading', false);
          return Promise.resolve(response);
        },
        (error) => {
          commit('clearQuestions', { questionTypeId });
          commit('setLoading', false);
          toastUnableToComplete();
          return Promise.reject(error);
        },
      );
    },
    setQuestionResponses({ commit }, { questionTypeId, resultId, responseCodes }) {
      const questions = this.state.validations.questions[questionTypeId];
      const update = questions.map((question, idx) => {
        if (question.resultId === resultId) {
          return idx;
        }
        return -1;
      })
        .filter((item) => item !== -1);
      if (update.length > 0) {
        const question = questions[update[0]];
        commit('updateQuestionsResponse', { questionTypeId, responseIdx: update[0], data: { ...question, responseCodes } });
      }
    },
    updateQuestions({ commit }, { questionTypeId, questions }) {
      commit('setLoading', true);
      return ValidationsService.updateQuestions({ questionTypeId, questions }).then(
        (response) => {
          commit('readQuestionsSuccess', { questionTypeId, data: response.data });
          commit('setLoading', false);
          toastSuccess('Salvataggio completato');
          return Promise.resolve(response);
        },
        (error) => {
          commit('clearQuestions', { questionTypeId });
          commit('setLoading', false);
          toastUnableToComplete();
          return Promise.reject(error);
        },
      );
    },
    createPersonalProfile({ commit }, { entityType, context }) {
      commit('setLoading', true);
      return ValidationsService.createValidation({ entityType, context, type: 'PROFILE' }).then(
        (response) => {
          commit('setLoading', false);
          return Promise.resolve(response.data);
        },
        (error) => {
          commit('setLoading', false);
          toastUnableToComplete();
          return Promise.reject(error);
        },
      );
    },
    readPersonalProfile({ commit }, { personalId }) {
      commit('setLoading', true);
      commit('clearPersonalProfile', { personalId });
      return ValidationsService.readPersonalProfile({ personalId }).then(
        (response) => {
          commit('readPersonalProfileSuccess', { personalId, data: response.data });
          commit('setLoading', false);
          return Promise.resolve(response.data);
        },
        (error) => {
          commit('clearPersonalProfile', { personalId });
          commit('setLoading', false);
          toastUnableToComplete();
          return Promise.reject(error);
        },
      );
    },
    updatePersonalProfile({ commit }, { personalId, profile }) {
      commit('setLoading', true);
      return ValidationsService.updatePersonalProfile({ personalId, profile }).then(
        (response) => {
          commit('readPersonalProfileSuccess', { personalId, data: response.data });
          commit('setLoading', false);
          toastSuccess('Salvataggio completato');
          return Promise.resolve(response);
        },
        (error) => {
          commit('clearPersonalProfile', { personalId });
          commit('setLoading', false);
          toastUnableToComplete();
          return Promise.reject(error);
        },
      );
    },
    setPersonalProfileAgreement({ commit }, { personalId, code, value }) {
      const detail = this.state.validations.personalProfiles[personalId];
      detail.personalResults = detail.personalResults.map((item) => {
        if (item.code === code) {
          return { ...item, response: value };
        }
        return item;
      });
      commit('readPersonalProfileSuccess', { personalId, data: detail });
    },
    setPersonalProfileDocument({ commit }, { personalId, document }) {
      const detail = this.state.validations.personalProfiles[personalId];
      detail.documents = detail.documents
        .map((doc) => ((doc.documentId === document.documentId) ? document : doc));
      commit('readPersonalProfileSuccess', { personalId, data: detail });
    },
    removePersonalProfile({ commit }, { personalId }) {
      commit('clearPersonalProfile', { personalId });
    },
  },
  mutations: {
    setLoading(state, loading) {
      state.loading = loading;
    },
    clearValidations(state) {
      state.subjectValidations = [];
      state.questions = {};
      state.personalProfiles = { };
    },
    clearQuestions(state, { questionTypeId }) {
      delete state.questions[questionTypeId];
    },
    clearPersonalProfile(state, { personalId }) {
      delete state.personalProfiles[personalId];
    },
    clearProfiles(state) {
      state.profiles = null;
    },
    loadValidationsSuccess(state, { data }) {
      state.subjectValidations = data;
    },
    readQuestionsSuccess(state, { questionTypeId, data }) {
      Vue.set(state.questions, questionTypeId, data);
    },
    updateQuestionsResponse(state, { questionTypeId, responseIdx, data }) {
      Vue.set(state.questions[questionTypeId], responseIdx, data);
    },
    readPersonalProfileSuccess(state, { personalId, data }) {
      Vue.set(state.personalProfiles, personalId, { ...data });
    },
    readProfilesSuccess(state, { data }) {
      state.profiles = data;
    },
    appendProfile(state, { profile }) {
      state.profiles = [...state.profiles, profile];
    },
    appendValidation(state, { validation }) {
      state.subjectValidations = [...state.subjectValidations, validation];
    },
  },
};

export default validations;
