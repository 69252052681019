import Vue from 'vue';
import VueRouter from 'vue-router';

const Investments = () => import('@/views/Investments.vue');
const ValidateToken = () => import('@/views/ValidateToken.vue');
const Register = () => import('../views/Register.vue');
const InvestimentoStart = () => import('../views/InvestimentoStart.vue');
const Home = () => import('../views/Home.vue');
const UserArea = () => import('../views/UserArea.vue');
const Login = () => import('../views/Login.vue');
const Investment = () => import('../views/Investment.vue');
const RecoverPassword = () => import('../views/RecoverPassword.vue');

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    //   name: 'Home',
    //   component: Home,
    // },
    // {
    //   path: '/investments',
    //   name: 'Investments',
    component: Investments,
  },
  {
    path: '/investi',
    name: 'InvestimentoStart',
    component: InvestimentoStart,
  },
  {
    path: '/progetti',
    name: 'ProjectList',
    component: Home,
    meta: {
      // // https://itnext.io/vue-tricks-smart-layouts-for-vuejs-5c61a472b69b
      layout: 'AppLayoutEmpty',
    },
  },
  {
    path: '/registrati',
    name: 'Register',
    component: Register,
  },
  {
    path: '/accedi',
    name: 'Login',
    component: Login,
  },
  {
    path: '/area-utenti',
    name: 'Area Utenti',
    component: UserArea,
  },
  {
    path: '/investment',
    name: 'Investment',
    component: Investment,
  },
  {
    path: '/investment/:projectId',
    name: 'InvestmentDetail',
    component: Investment,
  },
  {
    path: '/validate/:token',
    name: 'ValidateToken',
    component: ValidateToken,
  },
  {
    path: '/password-recovery',
    name: 'Recupero Password',
    component: RecoverPassword,
  },
  { path: '*', redirect: '/' },
];

const router = new VueRouter({
  mode: 'history',
  routes,
});

export default router;
