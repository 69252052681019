/* eslint-disable no-underscore-dangle */
import axiosInstance from './api';
import TokenService from './token.service';

const setup = (store, router) => {
  const ignoredPath = ['/login', '/logout', '/refresh'];
  axiosInstance.interceptors.request.use(
    (config) => {
      const token = TokenService.getLocalAccessToken();
      // console.log('axios inteceptor', token);
      if (token) {
        // eslint-disable-next-line no-param-reassign,dot-notation
        config.headers['Authorization'] = `Bearer ${token}`;
      }
      return config;
    },
    (error) => Promise.reject(error),
  );

  axiosInstance.interceptors.response.use(
    (res) => res,
    async (err) => {
      const originalConfig = err.config;

      if (ignoredPath.indexOf(originalConfig.url) === -1 && err.response) {
        // Access Token was expired
        if (err.response.status === 401 && !originalConfig._retry) {
          originalConfig._retry = true;

          try {
            await store.dispatch('auth/refreshToken');
            // const rs = await axiosInstance.post('/auth/refreshtoken', {
            //   refreshToken: TokenService.getLocalRefreshToken(),
            // });
            //
            // const { accessToken } = rs.data;
            //
            // store.dispatch('auth/refreshToken', accessToken);
            // TokenService.updateLocalAccessToken(accessToken);

            return axiosInstance(originalConfig);
          } catch (_error) {
            router.replace('/');
            return Promise.reject(_error);
          }
        }
      }

      return Promise.reject(err);
    },
  );
};

export default setup;
