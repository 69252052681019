import { isNotEmpty } from '@/utils/validators';
import TableUtilsService from '../services/tableUtils.service';

const baseList = {
  loading: false,
  list: null,
};

const initialState = {
  nationList: { ...baseList },
  mimeIcons: { ...baseList },
};

const tableUtils = {
  namespaced: true,
  state: initialState,
  getters: {
    isLoadingNation: (state) => state.nationList.loading,
    getNationList: (state) => state.nationList.list || [],
    hasNationList: (state) => isNotEmpty(state.nationList.list),
    isLoadingMimeIcons: (state) => state.mimeIcons.loading,
    getMimeIcons: (state) => state.mimeIcons.list || [],
    hasMimeIcons: (state) => isNotEmpty(state.mimeIcons.list),
  },
  actions: {
    loadNationList({ commit }) {
      // console.log('state', this.state);
      commit('setLoading', {
        tableName: 'nationList',
        loading: true,
      });
      return TableUtilsService.nations().then(
        (tableResponse) => {
          commit('tableUtilsSuccess', {
            tableName: 'nationList',
            tableResponse: tableResponse.data,
          });
          return Promise.resolve(tableResponse.data);
        },
        (error) => {
          commit('tableUtilsFailure', 'nationList');
          return Promise.reject(error);
        },
      );
    },
    loadMimeIcons({ commit }) {
      // console.log('state', this.state);
      commit('setLoading', {
        tableName: 'mimeIcons',
        loading: true,
      });

      return TableUtilsService.mimeIcons().then(
        (tableResponse) => {
          commit('tableUtilsSuccess', {
            tableName: 'mimeIcons',
            tableResponse: tableResponse.data,
          });
          return Promise.resolve(tableResponse.data);
        },
        (error) => {
          commit('tableUtilsFailure', 'nationList');
          return Promise.reject(error);
        },
      );
    },
  },
  mutations: {
    setLoading(state, { tableName, loading }) {
      state[tableName].loading = loading;
    },
    tableUtilsSuccess(state, { tableName, tableResponse }) {
      // console.log('tableName', tableName, tableResponse);
      state[tableName].list = tableResponse;
      state[tableName].loading = false;
    },
    tableUtilsFailure(state, tableName) {
      // console.log('tableUtilsFailure');
      state[tableName].loading = false;
    },
  },
};

export default tableUtils;
