import { request } from '@/utils/request';
import { isNotEmpty, isNotExist } from '@/utils/validators';

const apiBase = '/backoffice-api';
const apiProjectBase = '/project-api';

class SubjectService {
  registerPerson = (user) => {
    const {
      firstName, lastName, email, checkTerms, checkMarketing, mobilePhone,
    } = user;

    const privacyAccepts = [
      {
        privacyCode: 'accept',
        settingDate: new Date(),
        status: checkTerms,
      },
      {
        privacyCode: 'marketing',
        settingDate: new Date(),
        status: checkMarketing,
      },
    ];

    return request('/create-new-person', false,
      {
        method: 'post',
        data: {
          name: firstName,
          surname: lastName,
          email,
          mobilePhone,
          privacyAccepts,
        },
        apiBase,
      });
  }

  registerCompany = (user) => {
    const {
      companyName, vatNumber, firstName, lastName, email, checkTerms, checkMarketing, mobilePhone,
    } = user;

    const privacyAccepts = [
      {
        privacyCode: 'accept',
        settingDate: new Date(),
        status: checkTerms,
      },
      {
        privacyCode: 'marketing',
        settingDate: new Date(),
        status: checkMarketing,
      },
    ];

    return request('/create-new-company', false,
      {
        method: 'post',
        data: {
          name: firstName,
          surname: lastName,
          email,
          companyName,
          vatNumber,
          mobilePhone,
          privacyAccepts,
        },
        apiBase,
      });
  }

  loadSubject = () => request('/subject-detail', false, { apiBase })

  subjectStatus = () => request('/subject/status', false, { apiBase })

  subjectExtendedStatus = () => request('/extended-status', false, { apiBase })

  subjectSendValidation = () => request('/subject/send-validation', false, { method: 'post', apiBase })

  subjectSendUpdateRequest = () => request('/subject/update-enable', false, { method: 'post', apiBase })

  subjectSendTokenRequest = () => request('/subject/send-token', false, { method: 'post', apiBase })

  subjectCheckTokenRequest = (token) => request(`/subject/token/${token}`, true, { method: 'post', apiBase })

  savePerson = (person) => request('/person', false, {
    method: 'put',
    data: {
      ...person,
    },
    apiBase,
  })

  saveBank = ({ bank, entity }) => {
    const { status } = bank;
    let data;
    if (isNotExist(status)) {
      data = { ...bank, status: 'UNCHECKED' };
    } else {
      data = { ...bank };
    }

    return request(`/bank-account/${entity}`, false, {
      method: 'put',
      data,
      apiBase,
    });
  }

  saveSubject = ({
    person,
    company,
  }) => {
    let data = {};
    if (isNotEmpty(person)) {
      data = {
        ...data,
        person,
      };
    }

    if (isNotEmpty(company)) {
      data = {
        ...data,
        company,
      };
    }

    return request('/subject-detail', false, {
      method: 'put',
      data,
      apiBase,
    });
  };

  searchOwnInvestment = (search) => request('/user/subject/investment', false,
    {
      method: 'post',
      data: {
        ...search,
      },
      apiBase: apiProjectBase,
    });
}

export default new SubjectService();
