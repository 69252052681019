import { isArrayNotEmpty, isNotEmpty, isNotExist } from '@/utils/validators';
import { toastFailure, toastSuccess, toastUnableToComplete } from '@/utils/toast';
import Vue from 'vue';
import SubjectService from '../services/subject.service';

const initialState = {
  person: null,
  company: null,
  personIdentityDoc: null,
  personBankDoc: null,
  companyLegalIdentityDoc: null,
  companyChamberDoc: null,
  companyBankDoc: null,

  status: null,
  extendendStatus: null,
  loading: false,
  loadingStatus: false,
  investments: {
    loading: false,
    content: null,
    pageable: null,
  },
  error: {
    person: null,
    company: null,
  },
};

const extractBank = (subject) => {
  if (isNotExist(subject)) return {};

  if (isArrayNotEmpty(subject.bankInformationList)) {
    return subject.bankInformationList[0];
  }
  return {};
};

const subject = {
  namespaced: true,
  state: initialState,
  getters: {
    currentCompany: (state) => state.company,
    currentPerson: (state) => state.person,
    currentIdentityDoc: (state) => state.personIdentityDoc,
    currentPersonBankDoc: (state) => state.personBankDoc,
    currentChamberDoc: (state) => state.companyChamberDoc,
    currentLegalDoc: (state) => state.companyLegalIdentityDoc,
    currentCompanyBankDoc: (state) => state.companyBankDoc,
    currentBank: (state) => {
      if (isNotEmpty(state.company)) {
        return extractBank(state.company);
      }
      return extractBank(state.person);
    },
    currentPersonBank: (state) => extractBank(state.person),
    currentCompanyBank: (state) => extractBank(state.company),
    hasPerson: (state) => isNotEmpty(state.person),
    hasCompany: (state) => isNotEmpty(state.company),
    isLoading: (state) => state.loading,
    isLoadingStatus: (state) => state.loadingStatus,
    isLoadedData: (state) => isNotEmpty(state.person) || isNotEmpty(state.company),
    currentExtendedStatus: (state) => state.extendendStatus || {},
    canEditPerson: (state) => {
      const status = state.status || {};
      const { notOperative, operative, waitApproval } = (status['person-activity'] || {});
      return !notOperative && !operative && !waitApproval;
    },
    activityPersonStatus: (state) => {
      const status = state.status || {};
      return (status['person-activity'] || {});
    },
    isAnagraficalComplete: (state) => {
      const status = state.status || {};
      const personOnboard = status['person-onboard'] || {};
      const companyOnboard = status.onboard || {};

      // eslint-disable-next-line no-bitwise
      const personComplete = personOnboard.documents & personOnboard.person;
      let companyComplete = true;
      if (isNotEmpty(companyOnboard.companyInfo)) {
        const {
          documents, legal, legalAddess, companyInfo,
        } = companyOnboard;
        // eslint-disable-next-line no-bitwise
        companyComplete = documents & legal & legalAddess & companyInfo;
      }

      // eslint-disable-next-line no-bitwise
      return personComplete & companyComplete;
    },
    isBankComplete: (state) => {
      const status = state.status || {};
      if (isNotEmpty(state.company)) {
        const companyOnboard = status.onboard || {};
        return companyOnboard.bank;
      }
      const personOnboard = status['person-onboard'] || {};
      return personOnboard.bank;
    },
    canSendPersonValidation: (state) => {
      const status = state.status || {};
      const personActivity = status['person-activity'] || {};
      const personOnboard = status['person-onboard'] || {};

      // eslint-disable-next-line no-bitwise
      const personComplete = personOnboard.documents & personOnboard.person & personOnboard.bank;

      const keys = Object.keys(personActivity);
      if (keys.length === 0) return false;

      const calc = keys.map((key) => (personActivity[key] ? 1 : 0))
        .reduce((result, curr) => result + curr, 0);

      return personComplete && calc === 0;
    },
    canSendCompanyValidation: (state) => {
      const status = state.status || {};
      const companyActivity = status.activity || {};
      const companyOnboard = status.onboard || {};

      // eslint-disable-next-line no-bitwise
      const companyComplete = companyOnboard.documents & companyOnboard.company & companyOnboard.bank;

      const keys = Object.keys(companyActivity);
      if (keys.length === 0) return false;

      const calc = keys.map((key) => (companyActivity[key] ? 1 : 0))
        .reduce((result, curr) => result + curr, 0);

      return companyComplete && calc === 0;
    },
    hasInvestments: (state) => isNotEmpty(state.investments.pageable),
    investmentList: (state) => state.investments.content || [],
    investmentPageable: (state) => state.investments.pageable || {},
    getEntityType: (state) => (isNotEmpty(state.company) ? 'company' : 'person'),

  },
  actions: {
    logout({ commit }) {
      commit('loadSubjectFailure');
      return Promise.resolve();
    },
    registerPerson({ commit }, userData) {
      commit('setLoading', true);
      return SubjectService.registerPerson(userData).then(
        (response) => {
          commit('loadSubjectSuccess', response.data);
          commit('setLoading', false);
          return Promise.resolve(response.data);
        },
        (error) => {
          commit('registerPersonFailure');
          commit('setLoading', false);
          toastUnableToComplete();
          return Promise.reject(error);
        },
      );
    },
    registerCompany({ commit }, userData) {
      commit('setLoading', true);
      return SubjectService.registerCompany(userData).then(
        (response) => {
          commit('loadSubjectSuccess', response.data);
          commit('setLoading', false);
          return Promise.resolve(response.data);
        },
        (error) => {
          commit('registerCompanyFailure');
          commit('setLoading', false);
          toastUnableToComplete();
          return Promise.reject(error);
        },
      );
    },
    loadSubject({ commit }) {
      commit('setLoading', true);
      return SubjectService.loadSubject().then(
        (response) => {
          commit('loadSubjectSuccess', response.data);
          this.dispatch('subject/subjectStatus');
          return Promise.resolve(response.data);
        },
        (error) => {
          commit('loadSubjectFailure');
          commit('setLoading', false);
          toastUnableToComplete();
          return Promise.reject(error);
        },
      );
    },
    subjectStatus({ commit }) {
      commit('setLoadingStatus', true);
      return SubjectService.subjectStatus().then(
        (response) => {
          commit('subjectStatusSuccess', response.data);
          SubjectService.subjectExtendedStatus().then(
            (response1) => {
              commit('subjectStatusExtendedSuccess', response1.data);
              commit('setLoadingStatus', false);
              return Promise.resolve(response.data);
            },
            (error) => {
              commit('setLoadingStatus', false);
              toastUnableToComplete();
              return Promise.reject(error);
            },
          );
        },
        (error) => {
          commit('setLoadingStatus', false);
          toastUnableToComplete();
          return Promise.reject(error);
        },
      );
    },
    saveSubject({ commit }, { person, company, onlySave }) {
      commit('setLoading', true);
      return SubjectService.saveSubject({ person, company }).then(
        (response) => {
          commit('loadSubjectSuccess', response.data.data);
          if (onlySave) {
            toastSuccess('Salvataggio completato');
            commit('setLoading', false);
          }
          this.dispatch('subject/subjectStatus');
          return Promise.resolve(response);
        },
        (responseError) => {
          if (isNotEmpty(person)) {
            commit('setErrors', {
              section: 'person',
              errors: responseError.errors,
            });
          }
          if (isNotEmpty(company)) {
            commit('setErrors', {
              section: 'company',
              errors: responseError.errors,
            });
          }
          commit('setLoading', false);
          toastFailure('Salvataggio non completato');
          return Promise.reject(responseError);
        },
      );
    },
    saveBank({ commit }, bankUpdate) {
      commit('setLoading', true);
      return SubjectService.saveBank(bankUpdate).then(
        (response) => {
          // console.log('saveBank', response);
          if (response.status === 409) {
            const error = { field: 'iban', message: 'Iban già presente' };
            return Promise.reject(error);
          }

          commit('loadSubjectSuccess', response.data);
          toastSuccess('Salvataggio completato');
          this.dispatch('subject/subjectStatus');
          return Promise.resolve(response);
        },
        (responseError) => {
          commit('setErrors', { section: 'bank', errors: responseError.data.errors });
          toastFailure('Salvataggio non completato');
          commit('setLoading', false);
          return Promise.reject(responseError.data);
        },
      );
    },
    sendValidationRequest({ commit }) {
      commit('setLoading', true);
      return SubjectService.subjectSendValidation().then(
        (response) => {
          commit('loadSubjectSuccess', response.data);
          toastSuccess('Richiesta inviata');
          this.dispatch('subject/subjectStatus');
          return Promise.resolve(response);
        },
        (responseError) => {
          if (responseError.status === 412) {
            toastFailure("Verificare l'email prima di richiedere la validazione");
          } else {
            toastFailure('Invio richiesta non completato');
          }
          commit('setLoading', false);
          return Promise.reject(responseError);
        },
      );
    },
    sendUpdateRequest({ commit }) {
      commit('setLoading', true);
      return SubjectService.subjectSendUpdateRequest().then(
        (response) => {
          toastSuccess('Modifica dati attivata');
          this.dispatch('subject/subjectStatus');
          return Promise.resolve(response);
        },
        (responseError) => {
          toastFailure('Non è stato possibile abilitare la modifica');
          commit('setLoading', false);
          return Promise.reject(responseError);
        },
      );
    },
    sendTokenRequest({ commit }) {
      commit('setLoading', true);
      return SubjectService.subjectSendTokenRequest().then(
        (response) => {
          if (response.data) {
            toastSuccess('Richiesta inviata');
          } else {
            toastFailure('Invio richiesta non completato');
          }
          commit('setLoading', false);
          return Promise.resolve(response);
        },
        (responseError) => {
          commit('setLoading', false);
          return Promise.reject(responseError);
        },
      );
    },
    sendCheckTokenRequest({ commit }, token) {
      commit('setLoading', true);
      return SubjectService.subjectCheckTokenRequest(token).then(
        (response) => {
          commit('setLoading', false);
          return Promise.resolve(response.data);
        },
        (responseError) => {
          commit('setLoading', false);
          return Promise.reject(responseError);
        },
      );
    },
    updatePersonDocs({ commit }, { identityDoc, bankDoc }) {
      commit('updatePersonDoc', { personIdentityDoc: identityDoc, personBankDoc: bankDoc });
    },
    updateCompanyDocs({ commit }, { chamberDoc, legalDoc, bankDoc }) {
      commit('updateCompanyDoc', { currentChamberDoc: chamberDoc, currentLegalDoc: legalDoc, companyBankDoc: bankDoc });
    },
    clearInvestments({ commit }) {
      commit('clearInvestmentSearch');
    },
    searchInvestments({ commit }, { search, reset }) {
      commit('setInvestmentLoading', true);
      if (reset) {
        commit('clearInvestmentSearch');
      }
      return SubjectService.searchOwnInvestment(search).then(
        (response) => {
          commit('searchInvestmentSuccess', { data: response.data });
          commit('setInvestmentLoading', false);
          return Promise.resolve(response);
        },
        (error) => {
          commit('clearInvestmentSearch');
          commit('setInvestmentLoading', false);
          toastUnableToComplete();
          return Promise.reject(error);
        },
      );
    },
  },
  mutations: {
    setLoading(state, loading) {
      state.loading = loading;
    },
    setInvestmentLoading(state, loading) {
      state.investments.loading = loading;
    },
    setLoadingStatus(state, loading) {
      state.loadingStatus = loading;
    },
    registerPersonFailure(state) {
      console.log('registerPersonFailure');
      state.person = null;
      state.status = null;
      state.loading = false;
    },
    registerCompanyFailure(state) {
      console.log('registerCompanyFailure');
      state.company = null;
      state.status = null;
      state.loading = false;
    },
    loadSubjectSuccess(state, subjectData) {
      console.log('subject', subjectData);
      state.person = subjectData.person;
      state.company = subjectData.company;
      state.personIdentityDoc = subjectData.personIdentityDoc;
      state.personBankDoc = subjectData.personBankDoc;
      state.companyLegalIdentityDoc = subjectData.companyLegalIdentityDoc;
      state.companyChamberDoc = subjectData.companyChamberDoc;
      state.companyBankDoc = subjectData.companyBankDoc;

      state.status = null;
      state.loading = false;
      state.error = {
        person: null,
        company: null,
      };
    },
    subjectStatusSuccess(state, status) {
      // console.log('subject', subjectData);
      state.status = status;
    },
    subjectStatusExtendedSuccess(state, status) {
      // console.log('subject', subjectData);
      state.extendendStatus = status;
    },
    updatePersonDoc(state, { personIdentityDoc, personBankDoc }) {
      // console.log('subject', subjectData);
      if (isNotEmpty(personIdentityDoc)) {
        state.personIdentityDoc = personIdentityDoc;
      }
      if (isNotEmpty(personBankDoc)) {
        state.personBankDoc = personBankDoc;
      }
    },
    updateCompanyDoc(state, { currentChamberDoc, currentLegalDoc, companyBankDoc }) {
      // console.log('subject', subjectData);
      if (isNotEmpty(currentChamberDoc)) {
        state.currentChamberDoc = currentChamberDoc;
      }
      if (isNotEmpty(currentLegalDoc)) {
        state.currentLegalDoc = currentLegalDoc;
      }
      if (isNotEmpty(companyBankDoc)) {
        state.companyBankDoc = companyBankDoc;
      }
    },
    loadSubjectFailure(state) {
      console.log('loadSubjectFailure');
      state.person = null;
      state.company = null;
      state.personIdentityDoc = null;
      state.personBankDoc = null;
      state.companyLegalIdentityDoc = null;
      state.companyChamberDoc = null;
      state.companyBankDoc = null;

      state.loading = false;
      state.status = null;
    },
    setErrors(state, { section, errors }) {
      console.log('setErrors');
      state.error[section] = errors;
      state.loading = false;
    },
    clearInvestmentSearch(state) {
      const current = state.investments || { };
      Vue.set(state, 'investments', {
        ...current,
        content: null,
        pageable: null,
      });
    },
    searchInvestmentSuccess(state, { data }) {
      const { content, ...pageable } = data;
      const current = state.investments || { };
      Vue.set(state, 'investments', { ...current, content, pageable });
    },
  },
};

export default subject;
