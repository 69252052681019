// import axios from 'axios';
// import authHeader from '@/utils/auth-header';
import api from '@/services/api';
import { isPresent } from './validators';

//
//   https://www.bezkoder.com/vue-refresh-token/
//   https://blog.digital-craftsman.de/axios-interceptor-to-refresh-jwt-token-after-expiration/

// if data is not given at all, or it is a string, use it as it is,
// otherwise should be POJO, then make it a JSON
const formatData = (data) => (!data || typeof data === 'string' || data instanceof FormData
  ? data
  : JSON.stringify(data));

export const buildUrl = (url) => {
  const baseUrl = process.env.APP_BASENAME || '/';

  return `${baseUrl}${url}`;
};

export const request = (url, publicApi, options = {}) => {
  const {
    method = 'get',
    headers = {},
    data,
    fullPath,
    apiBase,
    processFailed,
    ...otherOptions
  } = options;

  // axiosInstance.interceptors.response.use(
  // (response) => {
  //   if ([401, 403].includes(response.status)) {
  //     if (!processFailed) {
  //       if (!window.location.pathname.includes('validation')) {
  //         window.location = '/';
  //       }
  //     }
  //     return Promise.reject(response);
  //   }
  //   if ([400].includes(response.status)) {
  //     return Promise.reject(response);
  //   }
  //   return response;
  // },
  // (error) => {
  //   const { response } = error;
  //   if (response) {
  //     if ([401, 403].includes(response.status)) {
  //       if (!processFailed) {
  //         window.location = '/';
  //       }
  //       return Promise.reject(error);
  //     }
  //     if ([400].includes(response.status)) {
  //       return Promise.reject(error);
  //     }
  //   }
  //   return response;
  // },
  // );

  const axiosInstance = api;

  let baseUrl = '';
  if (apiBase) {
    baseUrl = apiBase;
  }

  if (!isPresent(fullPath)) {
    if (publicApi) {
      baseUrl = `${baseUrl}/public/`;
    } else {
      baseUrl = `${baseUrl}/api/`;
    }
  } else {
    baseUrl = fullPath;
  }

  // console.log('baseUrl', baseUrl);

  // const authHeaders = publicApi ? {} : authHeader();
  const requestHeaders = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    'Cache-Control': 'no-cache',
    Pragma: 'no-cache',
    ...headers,
    // ...authHeaders,
  };

  return axiosInstance.request({
    baseURL: baseUrl,
    url,
    method,
    headers: requestHeaders,
    data: formatData(data),
    ...otherOptions,
  });
};

// export const setAuth = (value) => {
//   Vue.axios.defaults.headers.common.Authorization = `Bearer ${value.access_token}`;
// };
//
// export const deleteAuth = () => {
//   delete Vue.axios.defaults.headers.common.Authorization;
// };
