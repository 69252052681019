/* eslint no-use-before-define: 0 */  // --> OFF

// https://itnext.io/vue-tricks-smart-layouts-for-vuejs-5c61a472b69b

<template>
  <component :is="layout">
    <slot />
  </component>
</template>

<script>
const defaultLayout = 'AppLayoutDefault';

export default {
  name: "AppLayout",
  computed: {
    layout() {
      console.log('Layout check');
      const layout = this.$route.meta.layout || defaultLayout;
      console.debug('Layout', layout);
      return () => import(`@/layouts/${layout}.vue`);
    }
  }
}
</script>

<style scoped>

</style>
