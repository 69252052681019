const errorMessages = {
  'confirmEmail.sameAsEmail': 'Le email devono essere uguali',
  'confirmPassword.sameAsPassword': 'Le password devono essere uguali',
  'personTaxCode.checkTaxCode': 'Codice fiscale non valido',
  'anagraficalInfo.taxCode.checkTaxCode': 'Codice fiscale non valido',
  'iban.checkIban': 'Inserire un IBAN corretto',
  'iban.ibanNotDuplicate': 'IBAN già presente',
  'swift.checkBic': 'Codice BIC/SWIFT non valido',
  'anagraficalInfo.birthDate.checkAge': 'Deve essere maggiorenne',
  'additionalInfos.expireDate.checkExpired': 'Il documento deve essere valido',
  'additionalInfos.expireDate.wrong': 'La data di scadenza non è valida',
  'additionalInfos.expireDate.missing': 'La data di scadenza è obbligatoria',
  'additionalInfos.docNumber.missing': 'Il numero di documento è obbligatorio',
};

export default errorMessages;
