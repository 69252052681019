import { request } from '@/utils/request';

const apiBase = '/backoffice-api';

class TableUtilsService {
  nations = () => request('nation/list', false, { apiBase });

  mimeIcons = () => request('mime-icons', false, { apiBase });
}

export default new TableUtilsService();
