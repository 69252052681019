import Vue from 'vue';
import { isNotEmpty } from '@/utils/validators';
import EstateService from '@/services/estate.service';
import { toastSuccess, toastUnableToComplete } from '@/utils/toast';

const initialState = {
  estate: {
    content: null,
    pageable: null,
    currentProject: null,
    projectDocuments: null,
    subscriptionsAvailable: null,
  },
  loading: false,
  error: {
    estate: null,
  },
};

const project = {
  namespaced: true,
  state: initialState,
  getters: {
    isLoading: (state) => state.loading,
    currentProjectEstate: (state) => state.estate.currentProject,
    projectEstateDocuments: (state) => state.estate.projectDocuments || [],
    hasProjectEstateDocuments: (state) => isNotEmpty(state.estate.projectDocuments),
    hasProject: (state) => isNotEmpty(state.estate.currentProject),
    hasProjectsEstate: (state) => isNotEmpty(state.estate.pageable),
    projectEstateList: (state) => state.estate.content || [],
    subscriptionsAvailable: (state) => state.estate.subscriptionsAvailable || [],
    pagingEstate: (state) => state.estate.pageable || {},
  },
  actions: {
    searchProjectEstate({ commit }, { search, reset }) {
      commit('setLoading', true);
      if (reset) {
        commit('clearSearch', { type: 'estate' });
      }
      return EstateService.searchProject(search).then(
        (response) => {
          commit('searchSuccess', { data: response.data, type: 'estate' });
          commit('setLoading', false);
          return Promise.resolve(response);
        },
        (error) => {
          commit('searchFailure', { type: 'estate' });
          commit('setLoading', false);
          toastUnableToComplete();
          return Promise.reject(error);
        },
      );
    },
    readProjectEstate({ commit }, projectId) {
      commit('setLoading', true);
      commit('clearProject', { type: 'estate' });
      return EstateService.loadProject(projectId).then(
        (response) => {
          commit('loadProjectSuccess', { data: response.data, type: 'estate' });
          commit('setLoading', false);
          return Promise.resolve(response);
        },
        (error) => {
          commit('clearProject', { type: 'estate' });
          commit('setLoading', false);
          toastUnableToComplete();
          return Promise.reject(error);
        },
      );
    },
    readProjectDocuments({ commit }, { entityClass, entityId }) {
      commit('setLoading', true);
      return this.dispatch('documents/loadDocuments', { entityClass, entityId }).then(
        (response) => {
          commit('loadDocumentSuccess', { data: response, type: 'estate' });
          commit('setLoading', false);
          return Promise.resolve(response);
        },
        (error) => {
          commit('setLoading', false);
          toastUnableToComplete();
          return Promise.reject(error);
        },
      );
    },
    readSubscriptionsAvailable({ commit }, { projectId, entityType }) {
      commit('setLoading', true);
      return EstateService.subscriptionsAvailables({ projectId, entityType }).then(
        (response) => {
          commit('loadSubscriptionAvailable', { data: response.data, type: 'estate' });
          commit('setLoading', false);
          return Promise.resolve(response);
        },
        (error) => {
          commit('setLoading', false);
          toastUnableToComplete();
          return Promise.reject(error);
        },
      );
    },
    createInvestment({ commit }, {
      projectId, entityType, subscriptionCode, profileProtocolCode, amount, bank, simAccount,
    }) {
      commit('setLoading', true);
      return EstateService.createInvestment({
        projectId, entityType, subscriptionCode, profileProtocolCode, amount, bank, simAccount,
      }).then(
        (response) => {
          commit('setLoading', false);
          toastSuccess('Proposta di investimento conclusa');
          return Promise.resolve(response.data);
        },
        (error) => {
          commit('setLoading', false);
          toastUnableToComplete();
          return Promise.reject(error);
        },
      );
    },
  },
  mutations: {
    setLoading(state, loading) {
      state.loading = loading;
    },
    searchSuccess(state, { data, type }) {
      console.log('setSearch');
      const { content, ...pageable } = data;
      const current = state[type] || { };
      Vue.set(state, type, { ...current, content, pageable });
    },
    searchFailure(state, { type }) {
      console.log('search Failure');
      const current = state[type] || { };
      Vue.set(state, type, { ...current, content: null, pageable: null });
    },
    loadProjectSuccess(state, { data, type }) {
      const current = state[type] || { };
      Vue.set(state, type, { ...current, currentProject: data, subscriptionsAvailable: null });
    },
    loadSubscriptionAvailable(state, { data, type }) {
      const current = state[type] || { };
      Vue.set(state, type, { ...current, subscriptionsAvailable: data });
    },
    loadDocumentSuccess(state, { data, type }) {
      const current = state[type] || { };
      Vue.set(state, type, { ...current, projectDocuments: data });
    },
    clearSearch(state, { type }) {
      const current = state[type] || { };
      Vue.set(state, type, {
        ...current,
        content: null,
        pageable: null,
        currentProject: null,
        projectDocuments: null,
      });
    },
    clearProject(state, { type }) {
      const current = state[type] || { };
      Vue.set(state, type, {
        ...current, currentProject: null, projectDocuments: null, subscriptionsAvailable: null,
      });
    },
    setErrors(state, { section, errors }) {
      Vue.set(state.error, section, errors);
    },
  },
};

export default project;
