<template>
  <div id="app">
    <appLayout v-if="checked">
      <router-view/>
    </appLayout>
  </div>
</template>

<script>

export default {
  name: 'app',
  mounted() {
    console.log('current route', this.$route);
    if (this.$store.getters['auth/isLogged']) {
      this.$store.dispatch('auth/refreshToken').then(() => {
        console.log('refresh');
        this.checked = true;
      },
      () => {
        console.log('refresh failed', this.$route.name);
        if (this.$route.path !== '/') this.$router.replace('/');
        this.checked = true;
      });
    } else {
      this.checked = true;
    }
  },
  data() {
    return {
      checked: false,
    };
  },
  methods: {
  },
};
</script>

<style lang="scss">
#app {

}
</style>
