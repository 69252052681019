import DocumentService from '@/services/document.service';
import { isNotEmpty } from '@/utils/validators';
import { toastUnableToComplete } from '@/utils/toast';
import { buildDocName, decodeDocument } from '@/utils/documents';
import Vue from 'vue';

const initialState = {
  loading: false,
  documentTypes: null,
  updateDoc: {},
  documentWfw: null,
  documentsList: {},
  error: {
    updateDoc: null,
    documentTypes: null,
    documentWfw: null,
  },
};

const documents = {
  namespaced: true,
  state: initialState,
  getters: {
    documentTypes: (state) => state.documentTypes,
    documentWfw: (state) => state.documentWfw,
    isLoading: (state) => state.loading,
    isTypesLoaded: (state) => isNotEmpty(state.documentTypes),
    isWfwLoaded: (state) => isNotEmpty(state.documentWfw),
    decodeDocumentName: (state) => (doc) => {
      if (isNotEmpty(state.documentTypes)) {
        return decodeDocument(doc, state.documentTypes.translations);
      }
      return doc.documentInfo.documentType;
    },
    documentInfo: (state) => (doc) => {
      if (isNotEmpty(state.documentTypes)) {
        const docName = buildDocName(doc.documentInfo);
        return state.documentTypes.info[docName];
      }
      return '';
    },
    documentAdditionalInfos: (state) => (doc) => {
      if (isNotEmpty(state.documentTypes)) {
        const docName = buildDocName(doc.documentInfo);
        return state.documentTypes.additionalInfos[docName];
      }
      return '';
    },
    hasDocumentList: (state) => (entityClass, entityId) => {
      const entityType = entityClass.toLowerCase();
      const entityList = state.documentsList[entityType] || {};
      return isNotEmpty(entityList[entityId]);
    },
    getDocumentList: (state) => (entityClass, entityId) => {
      const entityType = entityClass.toLowerCase();
      const entityList = state.documentsList[entityType] || {};
      return entityList[entityId] || [];
    },
  },
  actions: {
    loadDocTypes({ commit }, language) {
      commit('setLoading', true);
      return DocumentService.documentTypes(language).then(
        (response) => {
          commit('typesSuccess', response.data);
          commit('setLoading', false);
          return Promise.resolve(response);
        },
        (responseError) => {
          commit('setErrors', { section: 'documentTypes', errors: true });
          commit('setLoading', false);
          toastUnableToComplete();
          return Promise.reject(responseError);
        },
      );
    },
    loadDocWfw({ commit }) {
      commit('setLoading', true);
      return DocumentService.documentWfw().then(
        (response) => {
          commit('wfwSuccess', response.data);
          commit('setLoading', false);
          return Promise.resolve(response);
        },
        (responseError) => {
          commit('setErrors', { section: 'documentWfw', errors: true });
          commit('setLoading', false);
          toastUnableToComplete();
          return Promise.reject(responseError);
        },
      );
    },
    updateDocument({ commit }, {
      document,
      entityClass,
      entityId,
    }) {
      commit('setLoading', true);
      return DocumentService.updateDocument({
        doc: document,
        entityClass,
        entityId,
        sectionCode: 'info',
      }).then(
        (response) => {
          commit('setLoading', false);
          return Promise.resolve(response.data);
        },
        (responseError) => {
          commit('setErrors', {
            section: 'updataDoc',
            errors: responseError.data.errors,
          });
          commit('setLoading', false);
          toastUnableToComplete();
          return Promise.reject(responseError.data);
        },
      );
    },
    loadDocuments({ commit }, {
      entityClass,
      entityId,
    }) {
      commit('setLoading', true);
      return DocumentService.loadEntityDocuments({
        entityClass,
        entityId,
      }).then(
        (response) => {
          commit('setDocumentLoaded', { entityClass, entityId, list: response.data });
          commit('setLoading', false);
          return Promise.resolve(response.data);
        },
        (responseError) => {
          commit('setLoading', false);
          toastUnableToComplete();
          return Promise.reject(responseError);
        },
      );
    },
  },
  mutations: {
    setLoading(state, loading) {
      state.loading = loading;
    },
    typesSuccess(state, types) {
      state.documentTypes = types;
      state.error.documentTypes = null;
    },
    wfwSuccess(state, types) {
      state.documentWfw = types;
      state.error.documentWfw = null;
    },
    setErrors(state, { section, errors }) {
      // console.log('setErrors');
      state.error[section] = errors;
    },
    setDocumentLoaded(state, { entityClass, entityId, list }) {
      const entityType = entityClass.toLowerCase();
      const entityList = state.documentsList[entityType] || {};
      entityList[entityId] = list;
      Vue.set(state.documentsList, entityType, entityList);
    },
  },
};

export default documents;
