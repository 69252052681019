import Vue from 'vue';
import * as GmapVue from 'gmap-vue';
import { BootstrapVue, BootstrapVueIcons } from 'bootstrap-vue';
import Vuelidate from 'vuelidate';
import axios from 'axios';
import VueAxios from 'vue-axios';
import AxiosPlugin from 'vue-axios-cors';
import { FontAwesomeIcon, FontAwesomeLayers, FontAwesomeLayersText } from '@fortawesome/vue-fontawesome';
import vSelect from 'vue-select';
import VueToast from 'vue-toast-notification';
import VueNumeric from 'vue-numeric';

// Da utilizzare per SEO
// https://medium.com/js-dojo/how-to-add-dynamic-meta-tags-to-your-vue-js-app-for-google-seo-b827e8c84ee9
import VueMeta from 'vue-meta';

import { fas } from '@fortawesome/free-solid-svg-icons';
import { fab } from '@fortawesome/free-brands-svg-icons';
import { far } from '@fortawesome/free-regular-svg-icons';
import { library } from '@fortawesome/fontawesome-svg-core';
import VueConfirmDialog from 'vue-confirm-dialog';
import { Datetime } from 'vue-datetime';
import { Settings } from 'luxon';
import VueFormWizard from 'vue-form-wizard';
import VueScrollTo from 'vue-scrollto';

import AppLayout from '@/layouts/AppLayout.vue';

import App from './App.vue';
import router from './router';
import store from './store';
import setupInterceptors from './services/setupInterceptors';

import '@fortawesome/fontawesome-free/css/fontawesome.min.css';
import 'vue-datetime/dist/vue-datetime.css';
import 'vue-toast-notification/dist/theme-sugar.css';
import 'vue2-dropzone/dist/vue2Dropzone.min.css';
import 'vue-form-wizard/dist/vue-form-wizard.min.css';

Vue.use(GmapVue, {
  load: {
    key: 'AIzaSyBtiyoVyPRdFAgNXswQtdu6zVYV2rrvkdA',
    libraries: 'places', // This is required if you use the Autocomplete plugin
    // OR: libraries: 'places,drawing'
    // OR: libraries: 'places,drawing,visualization'
    // (as you require)
  },
});

library.add(fab, fas, far);

Vue.config.productionTip = false;
Vue.use(Vuelidate);
Vue.use(BootstrapVue);
Vue.use(BootstrapVueIcons);
Vue.use(VueAxios, axios);
Vue.use(AxiosPlugin);
Vue.use(VueConfirmDialog);
Vue.use(Datetime);
Vue.use(VueMeta);
Vue.use(VueToast);
Vue.use(VueFormWizard);
Vue.use(VueScrollTo);
Vue.use(VueNumeric);

Vue.component('vue-confirm-dialog', VueConfirmDialog.default);
Vue.component('font-awesome-icon', FontAwesomeIcon);
Vue.component('font-awesome-layers', FontAwesomeLayers);
Vue.component('font-awesome-layers-text', FontAwesomeLayersText);
Vue.component('v-select', vSelect);
Vue.component('datetime', Datetime);
Vue.component('appLayout', AppLayout);

Settings.defaultLocale = 'it';

setupInterceptors(store);

new Vue({
  router,
  store,
  render: (h) => h(App),
  // watch: {
  //   $route(to, from) {
  //     console.log('route', to, from);
  //   },
  // },
}).$mount('#app');
