import Vue from 'vue';
// https://github.com/ankurk91/vue-toast-notification
const options = {
  position: 'top-right',
};

export const toastSuccess = (message) => Vue.$toast.success(message, options);
export const toastFailure = (message) => Vue.$toast.error(message, options);
export const toastInformation = (message) => Vue.$toast.info(message, options);
export const toastWarning = (message) => Vue.$toast.warning(message, options);

export const toastUnableToComplete = () => toastFailure('Operazione non completata');

export const showUnableToGetDocument = () => toastFailure('Non è stato possibile recuperare il documento');
export const showDocumentNotAvailable = () => toastFailure('Documento non disponibile, riprovare più tardi');
