import { request } from '@/utils/request';
import TokenService from '@/services/token.service';

const apiBase = '/project-api';

class EstateService {
  searchProject = (search) => request('/estate/projects', true,
    {
      method: 'post',
      data: {
        ...search,
      },
      apiBase,
    });

  loadProject = (projectId) => {
    console.log('TokenService', TokenService);
    return request(`/estate/project/${projectId}`, TokenService.isNotLogged(), { apiBase });
  }

  subscriptionsAvailables = ({ projectId, entityType }) => request(`/subscription/project/${projectId}/${entityType}`, false, { apiBase });

  createInvestment = ({
    projectId, entityType, subscriptionCode, profileProtocolCode, amount, bank, simAccount,
  }) => request(`/subscription/project/${projectId}/${entityType}`, false,
    {
      method: 'post',
      data: {
        subscriptionCode,
        profileProtocolCode,
        amount: {
          currency: 'EUR',
          amount,
        },
        bank,
        simAccount,
      },
      apiBase,
    });

  searchProjectInvestment = ({ projectId, search }) => request(`/user/estate/project-investment/${projectId}`, false,
    {
      method: 'post',
      data: {
        ...search,
      },
      apiBase,
    });
}

export default new EstateService();
