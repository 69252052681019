import Vue from 'vue';
import Vuex from 'vuex';
import auth from '@/store/auth.module';
import subject from '@/store/subject.module';
import documents from '@/store/documents.module';
import tableUtils from '@/store/tableUtils.module';
import project from '@/store/project.module';
import validations from '@/store/validations.module';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    auth,
    subject,
    documents,
    tableUtils,
    project,
    validations,
  },
});
